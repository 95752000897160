<template>
  <div>
    <Header> {{ title }} </Header>
    <div class="content" v-html="content"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      index: '',
      title: '',
      content: '',
    };
  },

  mounted() {
    this.index = this.$route.query.index;
    if (this.index == '0') {
      this.title = '用户注册协议';
      this.$api.anyInfo().then((res) => {
        if (res.code == 0) {
          if (this.index == this.$route.query.index) {
            this.content = res.data.agreement;
          }
        }
      });
    } else if (this.index == '1') {
      this.title = '平台使用规则';
      this.$api.anyInfo().then((res) => {
        if (res.code == 0) {
          if (this.index == this.$route.query.index) {
            this.content = res.data.rule;
          }
        }
      });
    } else if (this.index == '2') {
      this.title = '用户隐私协议';
      this.$api.anyInfo().then((res) => {
        if (res.code == 0) {
          if (this.index == this.$route.query.index) {
            this.content = res.data.hidement;
          }
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.content {
  margin-left: 34px;
  margin-right: 34px;
}
</style>
